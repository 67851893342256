import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Role {
    static list() {
        return new Promise((resolve, reject) => {
            return Api.get(`/roles`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static listPermissions() {
        return new Promise((resolve, reject) => {
            return Api.get('/permissions').then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            return Api.post(`/roles`,data).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static remove(id) {
        return new Promise((resolve, reject) => {
            return Api.delete(`/roles/${id}`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static update(data) {
        return new Promise((resolve, reject) => {
            return Api.put(`/roles/${data._id}`,data).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }
}
export default Role
