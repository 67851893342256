import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  Types,
  findByIdSuccess,
  createContactSucess,
  updateContactSuccess,
  deleteContactSuccess
} from "../reducers/Contact";
// import {saveStep} from "../reducers/Tutorial"

import ContactService from "../../services/Contact";

// import { buttonLoading, toggleModal } from "../actions/loading";
// import { errorMessage } from "../actions/FeedbackMessage";
// import { func } from "prop-types";

const findByIdRequest = async id =>
  await ContactService.listByCompany(id)
    .then(companie => companie)
    .catch(err => {
      throw err;
    });

const createContactRequest = async payload =>
  await ContactService.create(payload)
    .then(contact => {
      console.log(payload);
      return contact;
    })
    .catch(err => {
      throw err;
    });

const updateContactRequest = async (id, payload) =>
  await ContactService.update(id, payload)
    .then(contact => {
      return contact;
    })
    .catch(err => {
      throw err;
    });

const deleteContactRequest = async id =>
  await ContactService.delete(id)
    .then(() => {
      return null;
    })
    .catch(err => {
      throw err;
    });

function* createOneContact({ payload }) {
  try {
    console.log(payload);
    const contact = yield call(createContactRequest, payload);
    yield put(createContactSucess(contact));
  } catch (error) {
    yield put({ type: Types.CONTACT_CREATE_ERROR, payload: error });
  }
}

function* updateOneContact({ id, payload }) {
  try {
    const contact = yield call(updateContactRequest, id, payload);
    yield put(updateContactSuccess(contact));
  } catch (error) {
    yield put({ type: Types.CONTACT_UPDATE_ERROR, payload: error });
  }
}

function* deleteOneContact({ id, company_id }) {
  try {
    console.log("saga", id);
    yield call(deleteContactRequest, id);
    yield put(deleteContactSuccess(id));
    const contacts = yield call(findByIdRequest, company_id);
    yield put(findByIdSuccess(contacts));
  } catch (error) {
    yield put({ type: Types.CONTACT_DELETE_ERROR, payload: error });
  }
}

function* findById({ payload }) {
  try {
    const contacts = yield call(findByIdRequest, payload);
    yield put(findByIdSuccess(contacts));
  } catch (error) {
    yield put({ type: Types.CONTACT_FIND_BY_ID_ERROR, payload: error });
  }
}

export function* findByIdSaga() {
  yield takeLatest(Types.CONTACT_FIND_BY_ID, findById);
}

export function* createContactSaga() {
  yield takeLatest(Types.CONTACT_CREATE, createOneContact);
}

export function* updateContactSaga() {
  yield takeLatest(Types.CONTACT_UPDATE, updateOneContact);
}

export function* deleteContactSaga() {
  yield takeLatest(Types.CONTACT_DELETE, deleteOneContact);
}

export default function* rootSaga() {
  yield all([
    createContactSaga(),
    findByIdSaga(),
    updateContactSaga(),
    deleteContactSaga()
  ]);
}
