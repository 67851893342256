import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import companySagas from "./Company";
import botSagas from "./Bot";
import channelSagas from "./Channel";
import webhookSagas from "./Webhook"
import conversationSagas from "./Conversation"
import logsSagas from "./Logs"
import handleErrorSagas from "./handleError"
import feedbacksSagas from "./Feedbacks"
import filesSagas from "./Files"
import userSagas from "./User"
import roleSagas from "./Role"
import permissionSagas from "./Permission"
import contactSagas from "./Contact";
import interventionEventsSaga from "./InterventionEvents";
import calendarSagas from './Calendar'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    companySagas(),
    botSagas(),
    channelSagas(),
    webhookSagas(),
    conversationSagas(),
    logsSagas(),
    handleErrorSagas(),
    feedbacksSagas(),
    filesSagas(),
    userSagas(),
    roleSagas(),
    contactSagas(),
    interventionEventsSaga(),
    permissionSagas(),
    calendarSagas()
  ]);
}
