import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Permission {
    static async list() {
        try {
            const response = await Api.get('/permissions');
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async create(data) {
        try {
            
            const response = await Api.post('/permissions/create', data);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async remove(id) {
        try {
            const response = await Api.delete(`/permissions/${id}`);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async update(data) {
        try {
            const response = await Api.put(`/permissions/${data._id}`, data);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }
}


export const listAll = async () => await Permission.list();
export const list = async () => await Permission.list();
export const createData = async (data) => await Permission.create(data.payload);
export const updateData = async (data) => await Permission.update(data);
export const removeData = async (id) => await Permission.remove(id);

export default Permission;
