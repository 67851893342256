import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Contact {
  static list() {
    return new Promise((resolve, reject) => {
      return Api.get(`/calendars?select=name,slas`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static create(calendar) {
    return new Promise((resolve, reject) => {
      return Api.post(`/calendars`, calendar).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/calendars/${id}`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static update(id, calendar) {
    return new Promise((resolve, reject) => {
      return Api.put(`/calendars/${id}`, calendar).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static findById(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`/calendars/${id}`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static listCalendarResource(calendarId, resource) {
    return new Promise((resolve, reject) => {
      return Api.get(`/calendars/${calendarId}/${resource}`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static createCalendarResource(calendarId, resource, resourceData) {
    return new Promise((resolve, reject) => {
      return Api.post(`/calendars/${calendarId}/${resource}`, resourceData).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static updateCalendarResource(calendarId, resource, resourceId, resourceData) {
    return new Promise((resolve, reject) => {
      return Api.put(`/calendars/${calendarId}/${resource}/${resourceId}`, resourceData).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static deleteCalendarResource(calendarId, resource, resourceId) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/calendars/${calendarId}/${resource}/${resourceId}`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }
}
export default Contact
