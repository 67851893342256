import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import Service from "../../services/InterventionEvents";
import * as Actions from "../actions/InterventionEvents";
import { errorMessage, successMessage } from "../actions/FeedbackMessage";
import {
  INTERVENTION_EVENTS_LIST_ALL,
  INTERVENTION_EVENTS_CREATE,
  INTERVENTION_EVENTS_DELETE,
  INTERVENTION_EVENTS_UPDATE
} from "../../constants/ActionTypes";

const ERROR_TO_CREATE_EVENT = "Erro ao listar eventos de intervenção";

const getCompanyStore = state => state.company;

const listAllInterventionEventsRequest = async (id) =>
  await Service.listAllInterventionEvents(id)
    .then(actions => actions)
    .catch(error => { throw error});

const createInterventionEventRequest = async (data) =>
  await Service.createInterventionEvent(data)
    .then(actions => actions)
    .catch(error => { throw error});

const deleteInterventionEventRequest = async (id) =>
  await Service.deleteInterventionEvent(id)
    .then(actions => actions)
    .catch(error => { throw error});

const updateInterventionEventRequest = async (data) =>
  await Service.updateInterventionEvent(data)
    .then(actions => actions)
    .catch(error => { throw error});

function* listAllInterventionEvents({payload}) {
  try {
    yield put(Actions.interventionEventsComponentVisible(true))
    const events = yield call(listAllInterventionEventsRequest,payload)
    yield put(Actions.interventionEventsComponentVisible(false))
    yield put(Actions.interventionEventsListSuccess(events))
  } catch (error) {
    yield put(Actions.interventionEventsComponentVisible(false))
    yield put(Actions.interventionEventsListError());
  }
}

function* createInterventionEvent({payload}) {
  try {
    const event = yield call(createInterventionEventRequest,payload)
    yield put(Actions.interventionEventsCreateSuccess(event))
    yield put(Actions.interventionEventsModalVisible(false))
    yield put(successMessage("Ação cadastrado com sucesso"))
    try {
      const { company } = yield select(getCompanyStore)
      yield put(Actions.interventionEventsListAll(company._id))
    } catch (error) {
      yield put(errorMessage(ERROR_TO_CREATE_EVENT));
    }
  } catch (error) {
    yield put(Actions.interventionEventsCreateError());
    yield put(errorMessage(error));
  }
}

function* updateInterventionEvent({payload}) {
    try {
      yield call(updateInterventionEventRequest,payload)
      yield put(Actions.interventionEventsUpdateSuccess())
      yield put(Actions.interventionEventsModalVisible(false))
      yield put(successMessage("Evento de intervenção atualizado com sucesso"))
      try {
        const { company } = yield select(getCompanyStore)
        yield put(Actions.interventionEventsListAll(company._id))
      } catch (error) {
        yield put(errorMessage(ERROR_TO_CREATE_EVENT));
      }
    } catch (error) {
      yield put(Actions.interventionEventsUpdateError());
      yield put(errorMessage(error));
    }
}

function* deleteInterventionEvent({payload}) {
    try {
      yield call(deleteInterventionEventRequest,payload)
      yield put(Actions.interventionEventsDeleteSuccess())
      yield put(successMessage("Evento de intervenção apagado com sucesso"))
      try {
        const { company } = yield select(getCompanyStore)
        yield put(Actions.interventionEventsListAll(company._id))
      } catch (error) {
        yield put(errorMessage(ERROR_TO_CREATE_EVENT));
      }
    } catch (error) {
      yield put(Actions.interventionEventsDeleteError());
      yield put(errorMessage(error));
    }
}

export function* ListAllInterventionEventsSaga() {
  yield takeLatest(INTERVENTION_EVENTS_LIST_ALL, listAllInterventionEvents);
}

export function* createInterventionEventSaga() {
  yield takeLatest(INTERVENTION_EVENTS_CREATE, createInterventionEvent);
}

export function* deleteInterventionEventSaga() {
  yield takeLatest(INTERVENTION_EVENTS_DELETE, deleteInterventionEvent);
}

export function* updateInterventionEvenSaga() {
  yield takeLatest(INTERVENTION_EVENTS_UPDATE, updateInterventionEvent);
}

export default function* rootSaga() {
  yield all([
    fork(ListAllInterventionEventsSaga),
    fork(createInterventionEventSaga),
    fork(deleteInterventionEventSaga),
    fork(updateInterventionEvenSaga)
  ]);
}
