import {
  INTERVENTION_EVENTS_LIST_ALL,
  INTERVENTION_EVENTS_LIST_SUCCESS,
  INTERVENTION_EVENTS_LIST_ERROR,
  INTERVENTION_EVENTS_CREATE,
  INTERVENTION_EVENTS_CREATE_SUCCESS,
  INTERVENTION_EVENTS_CREATE_ERROR,
  INTERVENTION_EVENTS_DELETE_ERROR,
  INTERVENTION_EVENTS_DELETE_SUCCESS,
  INTERVENTION_EVENTS_DELETE,
  INTERVENTION_EVENTS_MODAL_VISIBLE,
  INTERVENTION_EVENTS_UPDATE,
  INTERVENTION_EVENTS_UPDATE_ERROR,
  INTERVENTION_EVENTS_UPDATE_SUCCESS,
  INTERVENTION_EVENTS_COMPONENT_VISIBLE
} from "../../constants/ActionTypes";

export const interventionEventsListAll = (id) => {
  return {
    type: INTERVENTION_EVENTS_LIST_ALL,
    payload: id
  };
};

export const interventionEventsListSuccess = (actions) => {
  return {
    type: INTERVENTION_EVENTS_LIST_SUCCESS,
    payload: actions
  };
};

export const interventionEventsListError = () => {
  return {
    type: INTERVENTION_EVENTS_LIST_ERROR
  };
};

export const interventionEventsCreate = (data) => {
  return {
    type: INTERVENTION_EVENTS_CREATE,
    payload: data
  };
};

export const interventionEventsCreateSuccess = (interventionEvents) => {
  return {
    type: INTERVENTION_EVENTS_CREATE_SUCCESS,
    payload: interventionEvents
  };
};

export const interventionEventsCreateError = () => {
  return {
    type: INTERVENTION_EVENTS_CREATE_ERROR
  };
};

export const interventionEventsModalVisible = (visible,create) => {
  return {
    type: INTERVENTION_EVENTS_MODAL_VISIBLE,
    payload: {
      visible,
      create
    }
  };
};

export const interventionEventsDeleteSuccess = (actions) => {
  return {
    type: INTERVENTION_EVENTS_DELETE_SUCCESS,
    payload: actions
  };
};

export const interventionEventsDeleteError = () => {
  return {
    type: INTERVENTION_EVENTS_DELETE_ERROR
  };
};

export const interventionEventsDelete = (id) => {
  return {
    type: INTERVENTION_EVENTS_DELETE,
    payload: id
  };
};

export const interventionEventsUpdateSuccess = () => {
  return {
    type: INTERVENTION_EVENTS_UPDATE_SUCCESS
  };
};

export const interventionEventsUpdateError = () => {
  return {
    type: INTERVENTION_EVENTS_UPDATE_ERROR
  };
};

export const interventionEventsUpdate = (interventionEvents) => {
  return {
    type: INTERVENTION_EVENTS_UPDATE,
    payload: interventionEvents
  };
};

export const interventionEventsComponentVisible = (loading) => {
  return {
    type: INTERVENTION_EVENTS_COMPONENT_VISIBLE,
    payload: loading
  };
};
