import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import ChannelService from "../../services/Channel";
import {
  CHANNEL_CREATE,
  CHANNEL_UPDATE,
  CHANNEL_GET_ALL,
  CHANNEL_DELETE
} from "../../constants/ActionTypes";
import {
  channelCreateSuccess,
  channelCreateError,
  channelUpdateSuccess,
  channelUpdateError,
  channelGetAllSuccess,
  channelGetAll,
  channelDeleteSuccess,
  channelDeleteError,
  channelDetailHide,
  channelModalVisible,
  channelComponentVisible
} from "../actions/Channel";
import { unloadingButton, loadingButton } from "../actions/loading";
import { errorMessage, successMessage } from "../actions/FeedbackMessage";

const getCompanyStore = state => state.company;

const createChannelRequest = async payload =>
  await ChannelService.create(payload)
    .then(channel => channel)
    .catch(error => {throw error});

const updateChannelRequest = async payload =>
  await ChannelService.update(payload)
    .then(channel => channel)
    .catch(error => {throw error});

const deleteChannelRequest = async payload =>
  await ChannelService.delete(payload)
    .then(channel => channel)
    .catch(error => {throw error});

const getAllChannelsRequest = async () =>
  await ChannelService.index()
    .then(channel => channel)
    .catch(error => {throw error});

function* createChannel(payload) {
  yield put(loadingButton());
  try {
    yield call(createChannelRequest, payload);

    yield put(unloadingButton());
    yield put(successMessage("Canal cadastrado com sucesso"));
    yield put(channelModalVisible(false, false));
    yield put(channelCreateSuccess());
    try {
      const {company} = yield select(getCompanyStore)
      yield put(channelGetAll(company._id));
      const channels = yield call(getAllChannelsRequest);
      if(channels){
        yield put(channelComponentVisible(false))
        yield put(channelGetAllSuccess(channels));
      }
    } catch (error) {
      yield put(errorMessage(error))
      yield put(errorMessage("Erro ao listar os canais"));
    }
  } catch (error) {
    yield put(unloadingButton());
    yield put(errorMessage(error));
    yield put(channelCreateError(error));
  }
}

function* deleteChannel(payload) {

  try {
    yield call(deleteChannelRequest, payload);

    yield put(successMessage("Canal deletado com sucesso"));
    yield put(channelDeleteSuccess());
    yield put(channelDetailHide());
    try {
      const {company} = yield select(getCompanyStore)
      yield put(channelGetAll(company._id));
    } catch (error) {
      yield put(errorMessage("Erro ao deleta o canal"));
    }
  } catch (error) {
    yield put(errorMessage(error));
    yield put(channelDeleteError(error));
  }
}

function* updateChannel(payload) {
  yield put(loadingButton());
  try {
    const channelUpdated = yield call(updateChannelRequest, payload);
    yield put(unloadingButton());
    yield put(successMessage("Canal atualizado com sucesso"));
    yield put(channelModalVisible(false, false));
    yield put(channelUpdateSuccess(channelUpdated));
    try {
      const {company} = yield select(getCompanyStore)
      yield put(channelGetAll(company._id));
      const channels = yield call(getAllChannelsRequest);
      if(channels){
        yield put(channelComponentVisible(false))
        yield put(channelGetAllSuccess(channels));
      }
    } catch (error) {
      yield put(errorMessage("Erro ao listar os canais"));
    }
  } catch (error) {
    yield put(unloadingButton());
    yield put(errorMessage(error));
    yield put(channelUpdateError(error));
  }
}

function* getAllChannel({ payload }) {
  try {
    yield put(channelComponentVisible(true))
    const channels = yield call(getAllChannelsRequest);
    if(channels){
      yield put(channelComponentVisible(false))
      yield put(channelGetAllSuccess(channels));
    }
  } catch (error) {
    yield put(channelComponentVisible(false))
  }
}

export function* channelCreateSaga() {
  yield takeEvery(CHANNEL_CREATE, createChannel);
}

export function* channelDeleteSaga() {
  yield takeEvery(CHANNEL_DELETE, deleteChannel);
}

export function* channelUpdateSaga() {
  yield takeEvery(CHANNEL_UPDATE, updateChannel);
}

export function* channelGetAllSaga() {
  yield takeEvery(CHANNEL_GET_ALL, getAllChannel);
}

export default function* rootSaga() {
  yield all([
    fork(channelCreateSaga),
    fork(channelUpdateSaga),
    fork(channelDeleteSaga),
    fork(channelGetAllSaga)
  ]);
}
