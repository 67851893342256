export const Types = {
  CALENDAR_LIST: 'CALENDAR_LIST',
  CALENDAR_LIST_SUCCESS: 'CALENDAR_LIST_SUCCESS',
  CALENDAR_CREATE: 'CALENDAR_CREATE',
  CALENDAR_UPDATE: 'CALENDAR_UPDATE',
  CALENDAR_DELETE: 'CALENDAR_DELETE',
  CALENDAR_FIND_BY_ID: 'CALENDAR_FIND_BY_ID',
  CALENDAR_FIND_BY_ID_SUCCESS: 'CALENDAR_FIND_BY_ID_SUCCESS',
  CALENDAR_LIST_RESOURCE: 'CALENDAR_LIST_RESOURCE',
  CALENDAR_LIST_RESOURCE_SUCCESS: 'CALENDAR_LIST_RESOURCE_SUCCESS',
  CALENDAR_CREATE_RESOURCE: 'CALENDAR_CREATE_RESOURCE',
  CALENDAR_DELETE_RESOURCE: 'CALENDAR_DELETE_RESOURCE',
  CALENDAR_UPDATE_RESOURCE: 'CALENDAR_UPDATE_RESOURCE',
}

const INITIAL_STATE = {
  // Full info: _id, name, slas, holidays and segments
  calendar: {},
  // With the basic information of the calendar: _id, name
  calendars: [],
}

export default function reducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case Types.CALENDAR_LIST_SUCCESS: {
      return {
        ...state,
        calendars: action.payload
      }
    }
    case Types.CALENDAR_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        calendar: action.payload
      }
    }
    case Types.CALENDAR_LIST_RESOURCE_SUCCESS: {
      return {
        ...state,
        calendar: { ...state.calendar, [action.resource]: action.payload }
      }
    }
    case Types.CALENDAR_CREATE:
    case Types.CALENDAR_CREATE_RESOURCE:
    case Types.CALENDAR_UPDATE:
    case Types.CALENDAR_DELETE:
    case Types.CALENDAR_UPDATE_RESOURCE: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

//* Action Creators
export const listCalendars = () => {
  return {
    type: Types.CALENDAR_LIST,
  }
};

export const listCalendarsSuccess = (calendars) => {
  return {
    type: Types.CALENDAR_LIST_SUCCESS,
    payload: calendars
  }
};

export const findById = (id) => {
  return {
    type: Types.CALENDAR_FIND_BY_ID,
    payload: id
  }
}

export const findByIdSuccess = (calendar) => {
  return {
    type: Types.CALENDAR_FIND_BY_ID_SUCCESS,
    payload: calendar
  }
}

export const createCalendar = (calendar, history) => {
  return {
    type: Types.CALENDAR_CREATE,
    payload: calendar,
    history
  }
}

export const updateCalendar = (id, calendar) => {
  return {
    type: Types.CALENDAR_UPDATE,
    id,
    payload: calendar
  }
}

export const deleteCalendar = (id) => {
  return {
    type: Types.CALENDAR_DELETE,
    id
  }
}

export const listCalendarResource = (calendarId, resource) => {
  return {
    type: Types.CALENDAR_LIST_RESOURCE,
    calendarId,
    resource
  }
}

export const listCalendarResourceSuccess = (resource, payload) => {
  return {
    type: Types.CALENDAR_LIST_RESOURCE_SUCCESS,
    resource,
    payload
  }
}

export const createCalendarResource = (calendarId, resource, resourceData) => {
  return {
    type: Types.CALENDAR_CREATE_RESOURCE,
    calendarId,
    resource,
    payload: resourceData
  }
}

export const updateCalendarResource = (calendarId, resource, resourceId, resourceData) => {
  return {
    type: Types.CALENDAR_UPDATE_RESOURCE,
    calendarId,
    resource,
    resourceId,
    payload: resourceData
  }
}

export const deleteCalendarResource = (calendarId, resource, resourceId) => {
  return {
    type: Types.CALENDAR_DELETE_RESOURCE,
    calendarId,
    resource,
    resourceId
  }
}
