import {
  CHANNEL_CREATE_SUCCESS,
  CHANNEL_CREATE_ERROR,
  CHANNEL_UPDATE_SUCCESS,
  CHANNEL_UPDATE_ERROR,
  CHANNEL_DELETE_SUCCESS,
  CHANNEL_DELETE_ERROR,
  CHANNEL_DETAIL_SHOW,
  CHANNEL_DETAIL_HIDE,
  CHANNEL_MODAL_VISIBLE,
  CHANNEL_COMPONENT_VISIBLE,
  CHANNEL_GET_ALL_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  channel: {
    settings: {
      embeddedUrls: ['']
    }
  },
  channels: [],
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case CHANNEL_CREATE_ERROR:
    case CHANNEL_DELETE_ERROR:
    case CHANNEL_UPDATE_ERROR: {
      return {
        ...state,
        mensagemErro: action.payload
      };
    }
    case CHANNEL_UPDATE_SUCCESS:
    case CHANNEL_CREATE_SUCCESS:
    case CHANNEL_DELETE_SUCCESS: {
      return {
        ...state
      };
    }
    case CHANNEL_DETAIL_SHOW: {
      return {
        ...state,
        redirect: true,
        channel: action.payload
      };
    }
    case CHANNEL_DETAIL_HIDE: {
      return {
        ...state,
        redirect: false
      }
    }
    case CHANNEL_MODAL_VISIBLE: {
      return {
        ...state,
        visible: action.payload.visible,
        create: action.payload.create
      };
    }
    case CHANNEL_COMPONENT_VISIBLE: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case CHANNEL_GET_ALL_SUCCESS: {
      return {
        ...state,
        channels: action.payload
      };
    }
    default:
      return state;
  }
};
