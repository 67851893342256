import { handleError } from '../util/ApiErrorUtil'
import Api from './Api'

class InterventionEvents {
  static listAllInterventionEvents() {
    return new Promise((resolve, reject) => {
      return Api.get(`/intervention-events`).then(interventionEvents => {
        resolve(interventionEvents)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static createInterventionEvent(data) {
    return new Promise((resolve, reject) => {
      return Api.post(`/intervention-events`,data).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static updateInterventionEvent(data) {
    return new Promise((resolve, reject) => {
      return Api.put(`/intervention-events/${data._id}`,data).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static deleteInterventionEvent(payload) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/intervention-events/${payload}`).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }
}

export default InterventionEvents
