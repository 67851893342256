// Action Types
export const Types = {
    LIST_PERMISSION: 'LIST_PERMISSION',
    CREATE_PERMISSION: 'CREATE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    DELETE_PERMISSION: 'DELETE_PERMISSION',
    LIST_PERMISSIONS_PAGE: 'LIST_PERMISSIONS_PAGE',
    LIST_PERMISSIONS_SUCCESS_PAGE: 'LIST_PERMISSIONS_SUCCESS_PAGE'
}

// Reducer
const INITIAL_STATE = {
    permissions: []
}

export default function reducer(state = INITIAL_STATE, action) {
    // console.log("=>",action.type);
    if(action.type === Types.LIST_PERMISSIONS_SUCCESS_PAGE){
        return {
            ...state,
            permission: action.payload
        }
    }else if(action.type === Types.LIST_PERMISSIONS_SUCCESS_PAGE) {
        return {
            ...state,
            permissions: action.payload
        }
    } else{
        return state;
    }
}

// Action Creators
export const listRoles = () => {
    return {
        type: Types.LIST_PERMISSION
    }
}

export const listPermissionSuccess = (permission) => {
    return {
        type: Types.LIST_PERMISSIONS_SUCCESS_PAGE,
        payload: permission
    }
}

export const listPermissions = () => {
    return {
        type: Types.LIST_PERMISSIONS_PAGE
    }
}

export const listPermissionsSuccess = (permissions) => {
    return {
        type: Types.LIST_PERMISSIONS_SUCCESS_PAGE,
        payload: permissions
    }
}

export const createPermission = (role) => {
    return {
        type: Types.CREATE_PERMISSION,
        payload: role
    }
}

export const updatePermission = (role) => {
    return {
        type: Types.UPDATE_PERMISSION,
        payload: role
    }
}

export const deletePermission = (id) => {
    return {
        type: Types.DELETE_PERMISSION,
        payload: id
    }
}
