import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {listAll, list, createData, updateData, removeData } from "../../services/Permission";
import { Types, listPermissionSuccess, listPermissionsSuccess } from "../reducers/Permission"
import { errorMessage } from "../actions/FeedbackMessage"
import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";


export function* listSaga() {
    yield takeLatest(Types.LIST_PERMISSION, function* list() {
        try {
            yield put(componentLoading(true))
    
            const roles = yield call(listAll)
    
            yield put(componentLoading(false))
            yield put(listPermissionSuccess(roles))
        } catch (error) {
            yield put(componentLoading(false))
            yield put(errorMessage(error))
        }
    }
    );
}

export function* listPermissionsSaga() {
    yield takeLatest(Types.LIST_PERMISSIONS_PAGE, function* listAllPermissions() {
    
        try{
            //GET ALL PERMISSIONS
            const permissions = yield call(list)
            //PUT ALL PERMISSIONS IN REDUX
            yield put(listPermissionsSuccess(permissions))
        }catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* createSaga() {
    yield takeLatest(Types.CREATE_PERMISSION, function* (payload) {
        try {
            yield put(buttonLoading(true))
    
            yield call(createData, payload)
    
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            const roles = yield call(listAll)
            //PUT ALL PERMISSIONS IN REDUX
            yield put(listPermissionSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            yield put(errorMessage(error))
        }
    });
}

export function* updateSaga() {
    yield takeLatest(Types.UPDATE_PERMISSION, function* update({payload}) {
        try {
            yield put(buttonLoading(true))
    
            yield call(updateData, payload)
    
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            const roles = yield call(listAll)
            yield put(listPermissionSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            yield put(errorMessage(error))
        }
    });
}

export function* deleteSaga() {
    yield takeLatest(Types.DELETE_PERMISSION, function* remove({payload}) {
        try {
            yield put(buttonLoading(true))
    
            yield call(removeData, payload)
    
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            const roles = yield call(listAll)
            yield put(listPermissionSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))
    
            yield put(errorMessage(error))
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(listPermissionsSaga),
        fork(createSaga),
        fork(updateSaga),
        fork(deleteSaga)
    ]);
}
