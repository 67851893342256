import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Contact {

	static list() {
		return new Promise((resolve, reject)=>{
			return Api.get('/customer').then(companies =>  {
				resolve(companies)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	}

	static listByCompany(id) {
		return new Promise((resolve, reject)=>{
			return Api.get(`/customer/all/${id}`).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	}

	static create(contact) {
		return new Promise((resolve, reject)=>{
			return Api.post('/customer', contact).then(contact =>  {
        console.log("Service");
				resolve(contact)
			}).catch((err) => {
        console.log(err);
				reject(handleError(err, "Ocorreu um erro ao criar o contato"))
			})
		})
	}

  static update(id, contact){
    return new Promise((resolve, reject) => {
      return Api.put(`/customer/${id}`, contact).then(contact => {
        resolve(contact)
      }).catch((err) => {
        console.log(err);
        reject(handleError(err, "Ocorreu um erro ao atualizar o contato"))
      })
    })
  }

  static delete(id){
    return new Promise((resolve, reject) => {
      return Api.delete(`/customer/${id}`).then(contact =>{
        resolve(contact)
      }).catch((err) =>{
        console.log(err);
        reject(handleError(err, "Ocorreu um erro ao deletar o contato"))
      })
    })
  }


}
export default Contact
