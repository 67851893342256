import { all, call, put, takeLatest } from "redux-saga/effects";
import { errorMessage, successMessage } from "../actions/FeedbackMessage";

import {
  Types,
  findByIdSuccess,
  listCalendarResourceSuccess,
  listCalendarsSuccess
} from "../reducers/Calendar";

import CalendarService from "../../services/Calendar";

const listCalendarsRequest = async () =>
  await CalendarService.list()
    .then(calendars => calendars)
    .catch(err => {
      throw err;
    });

const createCalendarRequest = async calendar =>
  await CalendarService.create(calendar)
    .then((calendar) => calendar)
    .catch(err => {
      throw err;
    });

const updateCalendarRequest = async (id, calendar) =>
  await CalendarService.update(id, calendar)
    .then(() => null)
    .catch(err => {
      throw err;
    });

const deleteCalendarRequest = async id =>
  await CalendarService.delete(id)
    .then(() => null)
    .catch(err => {
      throw err;
    });

const findByIdRequest = async id =>
  await CalendarService.findById(id)
    .then(calendar => calendar)
    .catch(err => {
      throw err;
    });

const listCalendarResourceRequest = async (calendarId, resource) =>
  await CalendarService.listCalendarResource(calendarId, resource)
    .then(resource => resource)
    .catch(err => {
      throw err;
    })

const createCalendarResourceRequest = async (calendarId, resource, resourceData) =>
  await CalendarService.createCalendarResource(calendarId, resource, resourceData)
    .then(() => null)
    .catch(err => {
      throw err;
    })

const updateCalendarResourceRequest = async (calendarId, resource, resourceId, resourceData) =>
  await CalendarService.updateCalendarResource(calendarId, resource, resourceId, resourceData)
    .then(() => null)
    .catch(err => {
      throw err;
    })

const deleteCalendarResourceRequest = async (calendarId, resource, resourceId) =>
  await CalendarService.deleteCalendarResource(calendarId, resource, resourceId)
    .then(() => null)
    .catch(err => {
      throw err;
    })

function* listAllCalendars() {
  try {
    const calendars = yield call(listCalendarsRequest);
    yield put(listCalendarsSuccess(calendars));
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* createCalendar({ payload, history }) {
  try {
    const calendar = yield call(createCalendarRequest, payload);
    history.push(`/calendars/${calendar._id}`);
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* updateCalendar({ id, payload }) {
  try {
    yield call(updateCalendarRequest, id, payload);
    yield put(successMessage("Calendário atualizado com sucesso!"));
    yield put({ type: Types.CALENDAR_LIST })
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* deleteOneCalendar({ id }) {
  try {
    yield call(deleteCalendarRequest, id);
    yield put(successMessage("Calendário deletado com sucesso!"));
    yield put({ type: Types.CALENDAR_LIST })
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* findById({ payload }) {
  try {
    const calendar = yield call(findByIdRequest, payload);
    yield put(findByIdSuccess(calendar));
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* listCalendarResource({ calendarId, resource }) {
  try {
    const resourceList = yield call(listCalendarResourceRequest, calendarId, resource);
    yield put(listCalendarResourceSuccess(resource, resourceList));
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* createCalendarResource({ calendarId, resource, payload }) {
  try {
    yield call(createCalendarResourceRequest, calendarId, resource, payload);
    yield put(successMessage("Recurso criado com sucesso!"));
    yield put({ type: Types.CALENDAR_LIST_RESOURCE, calendarId, resource })
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* updateCalendarResource({ calendarId, resource, resourceId, payload }) {
  try {
    yield call(updateCalendarResourceRequest, calendarId, resource, resourceId, payload);
    yield put(successMessage("Recurso atualizado com sucesso!"));
    yield put({ type: Types.CALENDAR_LIST_RESOURCE, calendarId, resource })
  } catch (error) {
    yield put(errorMessage(error));
  }
}

function* deleteCalendarResource({ calendarId, resource, resourceId }) {
  try {
    yield call(deleteCalendarResourceRequest, calendarId, resource, resourceId);
    yield put(successMessage("Recurso deletado com sucesso!"));
    yield put({ type: Types.CALENDAR_LIST_RESOURCE, calendarId, resource })
  } catch (error) {
    yield put(errorMessage(error));
  }
}

export function* createCalendarSaga() {
  yield takeLatest(Types.CALENDAR_CREATE, createCalendar);
}

export function* updateCalendarSaga() {
  yield takeLatest(Types.CALENDAR_UPDATE, updateCalendar);
}

export function* listAllCalendarsSaga() {
  yield takeLatest(Types.CALENDAR_LIST, listAllCalendars);
}

export function* deleteCalendarSaga() {
  yield takeLatest(Types.CALENDAR_DELETE, deleteOneCalendar);
}

export function* findByIdSaga() {
  yield takeLatest(Types.CALENDAR_FIND_BY_ID, findById);
}

export function* createCalendarResourceSaga() {
  yield takeLatest(Types.CALENDAR_CREATE_RESOURCE, createCalendarResource);
}

export function* updateCalendarResourceSaga() {
  yield takeLatest(Types.CALENDAR_UPDATE_RESOURCE, updateCalendarResource);
}

export function* listCalendarResourceSaga() {
  yield takeLatest(Types.CALENDAR_LIST_RESOURCE, listCalendarResource);
}

export function* deleteCalendarResourceSaga() {
  yield takeLatest(Types.CALENDAR_DELETE_RESOURCE, deleteCalendarResource);
}

export default function* rootSaga() {
  yield all([
    listAllCalendarsSaga(),
    createCalendarSaga(),
    updateCalendarSaga(),
    deleteCalendarSaga(),
    findByIdSaga(),
    listCalendarResourceSaga(),
    createCalendarResourceSaga(),
    updateCalendarResourceSaga(),
    deleteCalendarResourceSaga()
  ]);
}
