import {
  INTERVENTION_EVENTS_LIST_SUCCESS,
  INTERVENTION_EVENTS_LIST_ERROR,
  INTERVENTION_EVENTS_DELETE_ERROR,
  INTERVENTION_EVENTS_DELETE_SUCCESS,
  INTERVENTION_EVENTS_MODAL_VISIBLE,
  INTERVENTION_EVENTS_COMPONENT_VISIBLE
} from "../../constants/ActionTypes";

const INIT_STATE = {
  eventsMap: {
    'init': 'Atendimento iniciado'
  },
  interventionEvents: [],
  modalVisible: false,
  createModal: true,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INTERVENTION_EVENTS_LIST_SUCCESS: {
      return {
        ...state,
        interventionEvents: action.payload
      }
    }
    case INTERVENTION_EVENTS_DELETE_ERROR:
    case INTERVENTION_EVENTS_DELETE_SUCCESS:
    case INTERVENTION_EVENTS_LIST_ERROR: {
      return {
        ...state
      }
    }
    case INTERVENTION_EVENTS_MODAL_VISIBLE: {
      return {
        ...state,
        modalVisible: action.payload.visible,
        createModal: action.payload.create
      }
    } case INTERVENTION_EVENTS_COMPONENT_VISIBLE: {
      return {
        ...state,
        loading: action.payload
      }
    }
    default:
      return state;
  }
}
